/* From Uiverse.io by Cksunandh */
.triangle1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: rgb(234 179 8 / var(--tw-bg-opacity, 1)) transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
  margin-top: 20%; /* Default for large screens (lg) */
}

/* Adjust for medium screens (md) */
@media (max-width: 1024px) {
  .triangle1 {
    margin-top: 25%;
  }
}

/* Adjust for small screens (sm) */
@media (max-width: 640px) {
  .triangle1 {
    margin-top: 30%;
  }
}

.triangle2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent rgb(234 179 8 / var(--tw-bg-opacity, 1)) transparent;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }
  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.text {
  color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
  margin: 30px auto;
  text-align: center;
  font-weight: 500;
  letter-spacing: 4px;
}
